











































.chat-list-item {
	text-overflow: ellipsis;
}

.chat-list-title {
    line-height: 1.4;
}

.chat-list-item .chat-list-title,
.chat-list-item .chat-list-preview {
	font-weight: bold;
}

.chat-list-item.seen .chat-list-title,
.chat-list-item.seen .chat-list-preview {
	font-weight: normal;
}
