



























.message {
	width: 100%;
	clear: both;
	margin-bottom: 15px;
	display: inline-block;
}
.message-bubble {
	background-color: #eeeeee;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	padding: 10px 15px;
	position: relative;
	max-width: 80%;
}

.message-content {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.message-content > .message-content-text {
	white-space: pre-wrap;
	overflow: hidden;
	overflow-wrap: break-word;
}

.message-status {
	padding-left: 10px;
}

.message-bubble:before {
	content: "";
	width: 0px;
	height: 0px;
	top: 0px;
	position: absolute;
}

.message-bubble-left {
	float: left;
	margin-left: 14px;
}

.message-bubble-left:before {
	border-left: 8px solid transparent;
	border-right: 8px solid #eeeeee;
	border-top: 8px solid #eeeeee;
	border-bottom: 8px solid transparent;
	border-top-left-radius: 4px;
	left: -14px;
}

.message-bubble-right {
	float: right;
	margin-right: 14px;
}

.message-bubble-right:before {
	border-left: 8px solid #eeeeee;
	border-right: 8px solid transparent;
	border-top: 8px solid #eeeeee;
	border-bottom: 8px solid transparent;
	border-top-right-radius: 4px;
	right: -14px;
}
