




































































































































.attachment-wrapper {
    max-width: 300px;
}

.card {
    border-style: dashed;
    border-width: 2px;
}
