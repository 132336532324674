



































.html-message-content {
	overflow: hidden;
}
