




















































































































































































































































.card-block {
    height: calc(100vh - 140px);

    > .row {
        height: 100%;
    }
}

.chat-customers-pane,
.chat-messages-pane {
    height: 100%;
    overflow-y: hidden;
}
