














































































































































































































































































































































































































































.thread-view {
	height: 100%; // Makes chrome not break header...
	overflow-y: auto;

	> :last-child {
		margin-bottom: 1rem;
	}
}

.thread-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
