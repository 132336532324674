

































.message-attachment {
	max-width: 250px;
}
