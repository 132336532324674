





















































































































































































































































































































.thread-list {
	position: relative;
}

.thread-loading-indicator {
	position: absolute;
	z-index: 1000;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	text-align: center;
	padding: 15px;
	background: rgba(0, 0, 0, 0.1);
	color: #444;
}

.thread-list {
	min-height: 0;
	height: 100%;
	overflow-y: auto;
}

.thread-list.loading {
	opacity: 0.9;
}

.account-selector {
	flex-basis: 100%;
	text-align: center;
}

.card-header {
	flex-wrap: wrap;
}

.search-input {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	min-width: 100px;
}

.search-row-wrapper {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
}
