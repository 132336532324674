













































































































/deep/ .mail-event-content-outer {
    position: relative;
}

/deep/ .mail-event-content {
    transition: height 0.5s;
    text-overflow: ellipsis;
    overflow-y: hidden;
}

.mail-event-collapsed /deep/ .mail-event-content {
    max-height: 100px;
    transition: height 0.5s;
}

.ticket-mail-content-hide,
.ticket-mail-content-show {
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    text-align: center;
}
