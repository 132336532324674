














































































.mail-thread-list-item {
    border-radius: 0;
}
