








































.attachment {
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	position: relative;
    align-items: center;
}

.open-link {
    display: flex;
    overflow: hidden;
}

.attachment-actions {
    padding-left: 0.5rem;
}

.attachment-icon {
	flex: 0 1 auto;
	display: inline-flex;
	align-items: center;

	> span {
		font-size: 1.6em;
	}
}

.attachment-info {
	flex: 1 1 auto;
	line-height: 1.1;
	overflow: hidden;

	> .info-filename {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.attachment-actions {
	flex: 0 1 auto;

    > * {
        vertical-align: middle;
    }
}
