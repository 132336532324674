


































































































































































































































































.message-header {
    position: sticky;
    top: 0;
    background: var(--light);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.message-title {
    font-size: 1.125rem;
}

.attachment-wrapper {
    max-width: 300px;
}

.mail-collapsed {
    cursor: pointer;
    opacity: 0.9;
    & > * {
        pointer-events: none;
    }

    > .card-body {
        height: 90px;
        overflow: hidden;
    }
}

.mail-collapsed-overlay {
    position: absolute;
    z-index: 9999;
    left: 0; right: 0; top: 0; bottom: 0;
    background: linear-gradient(to bottom, rgba(100,100,100,0.4) 0%,rgba(100,100,100,0) 100%);

    display: flex;
    > .mail-collapsed-overlay-content {
        display: flex;
        align-self: center;
        margin: 0 auto;
    }
}
.mail-collapsed:hover .mail-collapsed-overlay {
    background: linear-gradient(to bottom, rgba(0,0,0,0.66) 0%,rgba(0,0,0,0) 100%);
}
