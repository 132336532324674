


















































.message {
	width: 100%;
	clear: both;
	margin-bottom: 15px;
	display: flex;
}
.message-bubble {
	background-color: #eeeeee;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	padding: 10px 15px;
	position: relative;
}

.message-bubble:before {
	content: "";
	width: 0px;
	height: 0px;
	top: 0px;
	position: absolute;
}

.message-bubble-left {
	margin-right: auto;
	margin-left: 14px;
}

.message-bubble-left:before {
	border-left: 8px solid transparent;
	border-right: 8px solid #eeeeee;
	border-top: 8px solid #eeeeee;
	border-bottom: 8px solid transparent;
	border-top-left-radius: 4px;
	left: -14px;
}

.message-bubble-right {
	margin-left: auto;
	margin-right: 14px;
}

.message-bubble-right:before {
	border-left: 8px solid #eeeeee;
	border-right: 8px solid transparent;
	border-top: 8px solid #eeeeee;
	border-bottom: 8px solid transparent;
	border-top-right-radius: 4px;
	right: -14px;
}

.message-text {
	white-space: pre-line;
}
