





































































.chat-input-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.chat-input {
    margin-bottom: 0;
    display: flex;
    flex: 1 1 auto;
}

.chat-input-btn {
    display: flex;
    flex: 0 1 auto;

    > .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.chat-input /deep/ .ql-container {
    width: 100%;
    border-radius: 0;
    max-height: 12em;

    > .ql-editor {
        border-radius: 0;
    }
}
