























































.sms-wrapper {
	height: calc(100vh - 88px);
}

.sms-wrapper > .sms-split {
	min-height: 0;
	height: 100%;

	> .card {
		height: 100%;
		&:not(:first-child) {
			&,
			> .card-header {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&:not(:last-child) {
			&,
			> .card-header {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}
