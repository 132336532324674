








































































.message-text {
    max-height: 3em;
}
