




















































































.attachment-upload {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    position: relative;
}

.attachment-upload-icon {
    flex: 0 1 auto;
    display: inline-flex;
    align-items: center;

    > span {
        font-size: 1.6em;
    }
}

.attachment-upload-info {
    flex: 1 1 auto;
    line-height: 1.1;
    overflow: hidden;

    > .info-filename {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.attachment-upload-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    transition: width 0.1s ease-out;
}

.attachment-remove {
    position: absolute;
    top: -6px;
    right: -6px;
    background: none;
    border: none;
    color: #dc3545;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
}

.attachment-upload:hover .attachment-remove {
    opacity: 1;
}

.attachment-remove:hover {
    color: #c82333;
}
