


















































.modal {
    display: block;
    overflow-y: auto;
}

.modal-enter-active .modal-backdrop,
.modal-leave-active .modal-backdrop {
    transition: opacity 0.3s;
}

.modal-enter .modal-backdrop,
.modal-leave-to .modal-backdrop {
    opacity: 0;
}

.modal-enter-active .modal-content,
.modal-leave-active .modal-content {
    transition: all 0.3s ease;
}

.modal-enter .modal-content,
.modal-leave-to .modal-content {
    transform: scale(0);
    opacity: 0;
}

