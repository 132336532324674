











































































































.feedback-table tr > td {
    border-top: 1px solid #888;
}
.feedback-table tr:last-child > td {
    border-bottom: 1px solid #888;
}
.feedback-table td {
    padding: 5px 10px;
}
.feedback-table td {
    border-left: 1px solid #888;
}
.feedback-table td:last-child {
    border-right: 1px solid #888;
}
