















































































































































































































































.messages-container {
	overflow-y: auto;
	height: 100%;
}
