








































































.customer-list-label {
  font-weight: bold;
}

.customer-list {
  height: 100%;
}

.customer-list-pane {
  // min-height: 110px;
  display: flex;
  flex-direction: column;

  > .list-group {
    overflow-y: auto;

    > .list-group-item:first-child {
      border-top: none;
    }
  }
}
