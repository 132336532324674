






















































































@import "~quill/dist/quill.bubble.css";

.card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
}

.card-body {
    overflow-y: auto;
}
