









































.conversation-list-preview {
	max-height: 42px;
	overflow: hidden;
	text-overflow: ellipsis;
}
