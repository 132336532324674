







































































































.facebook-chat-wrapper {
	height: calc(100vh - 88px);
}

.facebook-chat-wrapper {
	> .card,
	> .card > .card-block,
	.facebook-chat-split {
		min-height: 0;
		height: 100%;
	}
}

.facebook-chat-split > * {
	height: 100%;
}
