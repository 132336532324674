






































.statistics-box > .card-body {
    font-size: 3em;
    text-align: center;
    font-weight: bold;
    color: rgb(85, 85, 85);
}
